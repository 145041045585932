<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12" md="6" style="z-index:1;">
          <div class="map-container" v-if="status_mapa">
              <l-map :center="center" :options="{attributionControl: false}" :zoom="zoom" v-if="response != null" ref="mymap">
                  <l-control-attribution position="bottomright" prefix="Spore Cloud"></l-control-attribution>
                  <l-control-layers :collapsed="true" :sort-layers="true"/>
                  <l-tile-layer :key="tileProvider.name" :name="tileProvider.name" :url="tileProvider.url" :visible="tileProvider.visible" layer-type="base" v-for="tileProvider in tileProviders"/>
                  <l-control position="topright">
                  </l-control>
                  <l-marker :draggable="false" :lat-lng="marker" v-if="marker != null">
                    <l-icon :icon-size="[40,40]">
                      <avatarMarkerButton
                        :label="response.name.substr(0,2)"
                        :status="response.state"
                      />
                    </l-icon>
                  </l-marker>
                  <l-layer-group>
                    <v-polyline-decorator :paths="[polylinePointers]" :patterns="patternsTrail" />
                  </l-layer-group>
              </l-map>
          </div>
        </b-col>
        <b-col cols="12" md="6" v-if="!showConfig && response">
            <b-row align-h="end">
              <b-col>
                <b-button variant="flat-primary" class="btn-icon float-right btn-map rounded-circle"  @click="showConfig = true">
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" md="12">
                <alertPanicButton tipo="estado" :status="response.state" />
              </b-col>
              <b-col lg="4" md="12">
                <alertPanicButton tipo="energia" :status="'e' + response.energy" />
              </b-col>
              <b-col lg="4" md="12" v-if="userType === 'TS'">
                <alertPanicButton tipo="conexion" :status="'c' + response.conection" />
              </b-col>
            </b-row>
            <hr>
            <valueItem
              :value="response.name"
              :description="$t('Labels.Alias')"
              img="panicbutton"
            />
            <valueItem
              :value="response.site"
              :description="$t('Labels.Site')"
              img="georeference"
            />
            <a :href="'https://www.google.com/maps/dir/?api=1&origin=20.641252,-103.3005426&destination=' + response.latlong +'&travelmode=driving' " target="_blank">
              <valueItem
                :value="response.address"
                :description="$t('Labels.Address')"
                img="georeference"
              />
            </a>
            <hr>
            <b-row>
                <b-col md="6">
                  <valueItem
                    :value="response.contact_name || 'N/D'"
                    :description="$t('Labels.ContactName')"
                    img="contact"
                  />
                </b-col>
                <b-col md="6">
                  <valueItem
                    :value="response.contact_number || 'N/D'"
                    :description="$t('Labels.ContactNumber')"
                    img="phone"
                  />
                </b-col>
                <b-col md="6">
                  <valueItem
                    :value="response.secont_contact_name || 'N/D'"
                    :description="$t('Labels.ContactName')"
                    img="contact"
                  />
                </b-col>
                <b-col md="6">
                  <valueItem
                    :value="response.secont_contact_number || 'N/D'"
                    :description="$t('Labels.SecondContactNumber')"
                    img="phone"
                  />
                </b-col>
            </b-row>
            <hr>
            <valueItem
              :value="formDate(response.updated_at, 'dayJs')"
              :description="$t('Labels.LastUpdated')"
              img="fecha"
            ></valueItem>
            <hr>
            <b-row align-h="end">
              <b-col v-if="esperanding">
                <b-card>
                  <b-row>
                    <b-col>
                      <h4>{{$t('Messages.WaitingCmd')}}</h4>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col v-if="show">
                <b-card>
                  <b-row>
                    <b-col>
                      <h4>{{$t('Labels.Deactivate')}}</h4>
                      <b-form-checkbox class="custom-control-danger" name="check-button" v-model="relay" @change="sendAlarmState(response.uuid)" switch size="xl">
                        <span class="switch-icon-left">
                          <feather-icon icon="BellIcon"/>
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="BellOffIcon"/>
                        </span>
                      </b-form-checkbox>
                      <audio controls id="mySound" autoplay loop  hidden>
                        <source :src="require('@/assets/sounds/alarm/sound-alarm.mp3')" type="audio/wav">
                        Your browser does not support the audio element.
                      </audio>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                  <b-card>
                    <b-button :to="`/panic_button/sm/detail/${uuid}/history`" variant="outline-primary">
                      <feather-icon icon="AlertTriangleIcon" class="mr-50"/>
                      <span class="align-middle">{{$t('Labels.HistoryAlerts')}}</span>
                    </b-button>
                  </b-card>
              </b-col>
            </b-row>
        </b-col>

        <b-col cols="12" md="6" v-if="showConfig">
            <b-row align-h="end">
              <b-col>
                <b-button variant="flat-primary" class="btn-icon float-right btn-map rounded-circle"  @click="showConfig = false">
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>
            <b-form
                  class=""
                  @submit.prevent
                >
                  <validation-observer ref="configValidation">
                    <b-form-group
                      :label="$t('Labels.Alias')"
                      label-for="alias"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Alias"
                        rules="required"
                      >
                        <b-form-input
                          id="alias"
                          v-model="response.name"
                          :state="errors.length > 0 ? false:null"
                          name="alias"
                          :placeholder="$t('Labels.Alias')"
                          v-on:keyup.13="validationForm()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('Labels.Address')"
                      label-for="address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address"
                        rules="required"
                      >
                        <b-form-input
                          id="address"
                          v-model="response.address"
                          :state="errors.length > 0 ? false:null"
                          name="address"
                          :placeholder="$t('Labels.Address')"
                          v-on:keyup.13="validationForm()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('Labels.LatLong')"
                      label-for="latlong"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Latlong"
                        rules="required"
                      >
                        <b-form-input
                          id="latlong"
                          v-model="response.latlong"
                          :state="errors.length > 0 ? false:null"
                          name="latlong"
                          :placeholder="$t('Labels.LatLong')"
                          v-on:keyup.13="validationForm()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <hr>
                    <b-form-group
                      :label="$t('Labels.ContactName')"
                      label-for="contact_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="contact_name"
                        rules="required"
                      >
                        <b-form-input
                          id="contact_name"
                          v-model="response.contact_name"
                          :state="errors.length > 0 ? false:null"
                          name="contact_name"
                          :placeholder="$t('Labels.ContactName')"
                          v-on:keyup.13="validationForm()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('Labels.ContactNumber')"
                      label-for="contact_number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="contact_number"
                        rules="required"
                      >
                        <b-form-input
                          id="contact_number"
                          v-model="response.contact_number"
                          :state="errors.length > 0 ? false:null"
                          name="contact_number"
                          :placeholder="$t('Labels.ContactNumber')"
                          v-on:keyup.13="validationForm()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('Labels.ContactName')"
                      label-for="secont_contact_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="secont_contact_name"
                      >
                        <b-form-input
                          id="secont_contact_name"
                          v-model="response.secont_contact_name"
                          :state="errors.length > 0 ? false:null"
                          name="secont_contact_name"
                          :placeholder="$t('Labels.ContactName')"
                          v-on:keyup.13="validationForm()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('Labels.SecondContactNumber')"
                      label-for="secont_contact_number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="secont_contact_number"
                      >
                        <b-form-input
                          id="secont_contact_number"
                          v-model="response.secont_contact_number"
                          :state="errors.length > 0 ? false:null"
                          name="secont_contact_number"
                          :placeholder="$t('Labels.SecondContactNumber')"
                          v-on:keyup.13="validationForm()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      @click="validationForm"
                      class="mt-1"
                    >
                      {{$t("Labels.Save")}}
                    </b-button>
                  </validation-observer>
                </b-form>
        </b-col>
      </b-row>
      <b-card-header class="mt-2">
        <b-card-title>{{$t('Labels.LastAlerts')}}</b-card-title>
      </b-card-header>
      <div class="data-list-container">
          <b-table hover :fields="fields" :items="panicButtonAlerts" selectable select-mode="single" @row-selected="goToDetail" :responsive="true" tbody-tr-class="shadow-sm" table-variant="light">
            <template #cell(type)="item">
              <listItem
                :value="(item.value == 'A' ? $t('Messages.Alert') : item.value == 'E' ? $t('Messages.Energy') : item.value == 'C' ? $t('Messages.Connection') : $t('Messages.Other'))"
                :description="$t('Labels.Type')"
                img="panicbutton"
              />
            </template>
            <template #cell(created_at)="item">
              <listItem
                :value="(item.value ? formDate(item.value, 'dayJs') : '' )"
                :description="$t('Labels.Date')"
                img="fecha"
              />
            </template>
            <template #cell(user)="item">
              <listItem
                :value="(item.value ? item.value.first_name + ' ' + item.value.last_name : '')"
                :description="(item.value ? $t('Labels.DisabledBy') : '')"
                :img="(item.value ? 'contact' : null)"
              />
            </template>
            <template #cell(disabled_at)="item">
              <listItem
                :value="(item.value ? formDate(item.value, 'dayJs') : '' )"
                :description="(item.value ? $t('Labels.Date') : '')"
                :img="(item.value ? 'fecha' : null)"
              />
            </template>
          </b-table>
        </div>
    </b-card>
  </div>
</template>
<script>
import {
  LMap,
  LControlAttribution,
  LTileLayer,
  LMarker,
  LIcon,
  LControlLayers,
  LControl,
  LLayerGroup
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import request from '@/libs/request/index'
import { empty, timeZoneDate } from '@/libs/tools/helpers'
import alertPanicButton from '@core/spore-components/alertPanicButton/alertPanicButton.vue'
import listItem from '@core/spore-components/listItem/listItem.vue'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BTable
} from 'bootstrap-vue'
import L from 'leaflet'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import avatarMarkerButton from '@core/spore-components/avatarMarkerButton/avatarMarkerButton.vue'
import 'vue-slider-component/theme/antd.css'
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'

const tileProviders = [
  {
    name: 'Gris',
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    name: 'Calles',
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    name: 'Satelite',
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

export default {
  components: {
    LMap,
    LTileLayer,
    LControlAttribution,
    LControlLayers,
    LMarker,
    LIcon,
    BCard,
    BCardHeader,
    BCardTitle,
    LControl,
    BButton,
    valueItem,
    alertPanicButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BTable,
    listItem,
    ValidationProvider,
    ValidationObserver,
    avatarMarkerButton,
    LLayerGroup,
    'v-polyline-decorator': Vue2LeafletPolylineDecorator
  },

  data () {
    return {
      userType: '',
      zoom: 17,
      tileProviders,
      center: L.latLng(47.41322, -1.219482),
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: '',
      marker: null,
      draggable: true,
      staticAnchor: [12, 27],
      response: null,
      status_mapa: false,
      uuid: this.$route.params.uuid,
      required,
      showButtons: false,
      showConfig: false,
      centerAlert: null,
      esperanding: false,
      show: false,
      relay: false,
      trailPointers: [],
      polylinePointers: [],
      panicButtonAlerts: [],
      fields: [
        { key: 'type', label: this.$t('Labels.Type'), sortable: true },
        { key: 'created_at', label: this.$t('Labels.AlertedOn'), sortable: true },
        { key: 'disabled_at', label: this.$t('Labels.DisabledOn'), sortable: true },
        { key: 'user', label: this.$t('Labels.DisabledBy'), sortable: true }
      ],
      patternsTrail: [{
        offset: 25,
        repeat: 50,
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          pathOptions: {
            fillOpacity: 1,
            weight: 5
          }
        })
      }]
    }
  },

  methods: {
    formDate (date, type) {
      return timeZoneDate(date, type)
    },
    goToDetail (data) {
      this.$router.push(`/panic_button/sm/detail/${this.uuid}/history/${data[0].uuid}`)
    },
    async getResponse () {
      const params = {
        url: `panic_button/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = data.data.data
        this.show = false
        this.relay = false
        this.esperanding = false
        if ((this.response.state === '1') && (this.response.state_platform === '1')) {
          this.show = true
          this.relay = true
        }
        if ((this.response.state === '0') && (this.response.state_platform === '1')) {
          this.esperanding = true
          this.show = false
          this.relay = false
        }
        this.panicButtonAlerts = this.response.panic_button_alerts
        this.response.notifications = this.response.enabled_notifications === '1'
        this.response.alertInOut = this.response.alert_in_radius === 'I'
        this.response.time_notifications = parseInt(this.response.time_notifications)
        if (!empty(this.response.latlong_alert_string)) {
          this.centerAlert = L.latLng(this.response.latlong_alert_string.split(',')[0], this.response.latlong_alert_string.split(',')[1])
        }
        if (!empty(this.response.latlong)) {
          this.marker = L.latLng(this.response.latlong.split(',')[0], this.response.latlong.split(',')[1])
          this.center = L.latLng(this.response.latlong.split(',')[0], this.response.latlong.split(',')[1])
          this.status_mapa = true
          const app = this
          setTimeout(function () {
            const maps = app.$refs.mymap.mapObject
            maps.addControl(new L.Control.Fullscreen({
              position: 'topleft'
            }))
            app.showButtons = true
          }, 1000)
        }
      })
    },
    async save () {
      const params = {
        url: `panic_button/${this.uuid}/update`,
        method: 'PUT',
        params: {
          latlong: this.response.latlong,
          name: this.response.name,
          address: this.response.adress,
          contact_name: this.response.contact_name,
          contact_number: this.response.contact_number,
          secont_contact_name: this.response.secont_contact_name,
          secont_contact_number: this.response.secont_contact_number
        }
      }
      await request(params).then(() => {
        this.showConfig = false
        const maps = this.$refs.mymap.mapObject
        maps.fitBounds([[this.marker.lat, this.marker.lng]])
      })
    },
    validationForm () {
      this.$refs.configValidation.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
    sendAlarmState (uuid) {
      this.sendComand(uuid, 'M', 'R', this.relay ? '1' : '0')
    },
    sendComand (uuid, type, command, state) {
      this.$swal({
        title: '¿Desactivar la Alerta?',
        html:
        'Escriba el motivo por el cual<br>' +
        'se desactiva esta alerta' +
        '<input id="swal-input1" placeholder="Quien responde" class="swal2-input">' +
        '<textarea id="textarea1" placeholder="Escriba sus comentarios..." class="swal2-textarea" ></textarea>',
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('textarea1').value
          ]
        },
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          if (result.value[0] !== '' && result.value[1] !== '') {
            const params = {
              url: `/panic_button/${uuid}/close`,
              method: 'POST',
              params: {
                attended: result.value[0],
                observations: result.value[1]
              }
            }
            await request(params).then(data => {
            })
            this.showConfig = false
            this.relay = false
            this.show = false
            this.getResponse()
          } else {
            this.$swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: 'Favor de llenar los campos !!!'
            })
            this.relay = true
            this.show = true
            this.attended = ''
            this.observations = ''
          }
        } else {
          this.relay = true
          this.show = true
          this.attended = ''
          this.observations = ''
        }
      })
    }
  },
  watch: {
    showConfig (val) {
      if (val) {
        const maps = this.$refs.mymap.mapObject
        const pointsCenter = []
        pointsCenter.push([this.marker.lat, this.marker.lng])
        if (!empty(this.centerAlert)) {
          pointsCenter.push([this.centerAlert.lat, this.centerAlert.lng])
        }
        maps.fitBounds(pointsCenter)
      }
    }
  },
  sockets: {
    panic_button (payload) {
      if (payload.uuid === this.uuid) {
        if (!empty(payload.latlong)) {
          this.marker = L.latLng(payload.latlong.split(',')[0], payload.latlong.split(',')[1])
          this.center = L.latLng(payload.latlong.split(',')[0], payload.latlong.split(',')[1])
          this.response.latlong = payload.latlong
          this.response.energy = payload.energy
          this.response.conection = payload.conection
          this.response.state = payload.state
          this.response.state_platform = payload.state_platform
          if (payload.state === '1') {
            this.relay = true
            this.show = true
          } else {
            this.relay = false
            this.show = false
          }
          this.response.updated_at = payload.updated_at
          this.response.name = payload.name
        }
      }
    }
  },
  created () {
    this.getResponse()
    this.userType = this.$store.state.session.AppActiveUser().userRole
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Detail.scss";
</style>
<style scoped>
  .vue2leaflet-map .leaflet-shadow-pane {
    display: none !important;
  }

  .vs-input--label {
    padding: 0;
  }
</style>
