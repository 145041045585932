<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 38.376 45.851"
    aria-labelledby="Police Icon"
    role="presentation"
  >
    <path id="Path_4402" data-name="Path 4402" d="M309.879,161.261l-2.807-9.827a8.877,8.877,0,0,1,2.322-8.78,1.342,1.342,0,0,0,0-1.9L303.639,135a1.344,1.344,0,0,0-1.843-.053c-.02.017-.04.036-.058.054a6.794,6.794,0,0,1-9.608,0,1.345,1.345,0,0,0-1.9,0,6.794,6.794,0,0,1-9.608,0c-.019-.018-.039-.037-.058-.054a1.344,1.344,0,0,0-1.843.053l-5.756,5.756a1.342,1.342,0,0,0,0,1.9,8.88,8.88,0,0,1,2.323,8.78l-2.807,9.827a12.8,12.8,0,0,0,12.309,16.319,5.493,5.493,0,0,1,3.055.924l2.592,1.728a1.343,1.343,0,0,0,1.489,0l2.592-1.728a5.489,5.489,0,0,1,3.054-.924,12.8,12.8,0,0,0,12.31-16.319Zm-11.48-5.289-2.526,3.016.271,3.925a.959.959,0,0,1-1.316.957l-3.649-1.471-3.65,1.471a.96.96,0,0,1-1.316-.957l.271-3.925-2.526-3.016a.959.959,0,0,1,.5-1.547l3.817-.954,2.088-3.336a.96.96,0,0,1,1.626,0l2.088,3.336,3.817.954a.959.959,0,0,1,.5,1.547Z" transform="translate(-271.991 -134.606)" :fill="iconColor"/>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#004ba9'
    }
  }
}
</script>
