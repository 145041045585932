<template>
  <span>
    <b-avatar
      size="40px"
      :text="label"
      :variant="variant"
      :class="styles"
      badge
      badge-top
      badge-variant="variant"
    >
      <template #badge>
        <iconPolice v-if="status === '1'"
          class="alerIcon"
          width="20"
          height="20"
        />
      </template>
    </b-avatar>
  </span>
</template>
<script>
import {
  BAvatar
} from 'bootstrap-vue'

import iconPolice from '@core/spore-components/icons/iconPolice'

export default {
  components: {
    BAvatar,
    iconPolice
  },
  props: {
    label: String,
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    styles () {
      return this.status === '1' ? 'avatar-alert onAlert' : 'avatar-no-alert'
    },
    variant () {
      return this.status === '1' ? 'light-danger' : 'light-success'
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/avatarMarker.scss";
</style>
