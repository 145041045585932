<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 46.483 46.483"
    aria-labelledby="No alert Icon"
    role="presentation"
  >
    <g id="Group_2817" data-name="Group 2817" transform="translate(-21.138 -279.397)">
      <path id="Path_1914" data-name="Path 1914" d="M44.38,279.4a23.242,23.242,0,1,0,23.241,23.242A23.243,23.243,0,0,0,44.38,279.4Zm0,41.744a18.5,18.5,0,1,1,18.5-18.5A18.5,18.5,0,0,1,44.38,321.141Z" :fill="iconColor"/>
      <circle id="Ellipse_108" data-name="Ellipse 108" cx="14.886" cy="14.886" r="14.886" transform="translate(29.494 287.753)" :fill="iconColor"/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#34C672'
    }
  }
}
</script>
