<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 -5 23 35">
    <path fill="#b6b6b6" d="M17,10H13L17,2H7V4.18L15.46,12.64M3.27,3L2,4.27L7,9.27V13H10V22L13.58,15.86L17.73,20L19,18.73L3.27,3Z" />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 35
    },
    height: {
      type: [Number, String],
      default: 35
    }
  }
}
</script>
