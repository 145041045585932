<template>
  <div>
      <div class="device-info">
          <div class="shadow-alert">
            <b-avatar variant="light" class="shadow-alert">
              <iconConnection v-if="tipo === 'conexion' && status === 'c2'"/>
              <iconFlashOff v-else-if="tipo === 'energia' && status === 'e2'"/>
              <iconAlarm v-else-if="tipo === 'estado' && status === '1'"/>
              <iconNoAlert v-else/>
            </b-avatar>
          </div>
          <div class="device-text-container">
            <span class="main-info" v-if="status === 'c2'">{{$t('Messages.NoConnected')}}</span>
            <span class="main-info" v-else-if="status === 'e2'">{{$t('Messages.NoEnergized')}}</span>
            <span class="main-info" v-else-if="status === '1'">{{$t('Messages.Alert')}}</span>
            <span class="main-info" v-else>{{$t('Messages.NoAlert')}}</span>
            <span class="subtext-info" v-if="tipo === 'conexion'">{{$t("Messages.Connected")}}</span>
            <span class="subtext-info" v-else-if="tipo === 'energia'">{{$t("Messages.Energized")}}</span>
            <span class="subtext-info" v-else-if="tipo === 'estado'">{{$t("Messages.State")}}</span>
          </div>
      </div>
  </div>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'
import iconAlarm from '@core/spore-components/icons/iconAlarm'
import iconFlashOff from '@core/spore-components/icons/iconFlashOff'
import iconConnection from '@core/spore-components/icons/iconConnection'
import iconNoAlert from '@core/spore-components/icons/iconNoAlert'

export default {
  components: {
    BAvatar,
    iconAlarm,
    iconFlashOff,
    iconConnection,
    iconNoAlert
  },
  props: {
    tipo: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/alertItem";
</style>
